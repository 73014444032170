@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@layer components {
  bold {
    @apply font-bold
  }
  #for-wheels p {
    @apply text-[#393939]
  }
  .container {
    @apply m-auto max-w-[1200px]
  }
  .footer-navlink {
    @apply cursor-pointer hover:text-[#000]
  }
  .navitem {
    @apply flex flex-col items-center text-base font-light
  }
  .active span {
    @apply flex
  }
  .contact form label {
    @apply flex flex-col gap-1
  }
  .contact form label span {
    @apply opacity-70
  }
  .contact form label input {
    @apply bg-[#d0d5dd] focus:outline-none rounded p-3
  }
  .contact form label textarea {
    @apply bg-[#d0d5dd] focus:outline-none rounded p-3
  }
  a span {
    @apply flex bg-[#d9d9d9] h-3 w-3 rounded-full
  }
  a .active {
    @apply gap-8
  }
  a .active span {
    @apply rounded-none w-7 h-0 bg-none border border-black/70 transition-all duration-200
  }
  .work-subhead {
    @apply text-[27px] md:text-4xl lg:text-5xl my-8 font-dmsans
  }
  .mini-head {
    @apply font-dmsans font-semibold text-[28px] my-5
  }
  .realisation-tag {
    @apply bg-[#F6F3F2] w-fit p-3 rounded flex items-center gap-3 text-sm md:text-base
  }
  .pos-nev {
    @apply w-fit rounded-t-3xl rounded-br-3xl py-1 px-5 font-bold text-white text-lg
  }
  .pos-nev-deets {
    @apply bg-[#01322D]/10 p-3 rounded-r-3xl rounded-bl-3xl
  }
  .table-item {
    @apply w-full flex flex-col md:flex-row justify-between items-start mt-5
  }
  .table-item h3 {
    @apply font-bold
  }
  .table-item p {
    @apply px-2 md:px-0 md:w-[60%]
  }
  .persona-card {
    @apply flex flex-col md:flex-row gap-4 md:gap-8
  }
  .persona-card .item {
    @apply bg-[#F0F3F3] text-sm p-3
  }
  .persona-card .item span {
    @apply text-[#036358]
  }
  .scenario-flow {
    @apply bg-[#C7F8E2] text-center rounded text-xs md:text-sm py-3 px-2
  }
  .ur-card {
    @apply flex flex-col gap-3 items-center justify-center border border-[#1B4BB7] rounded-lg lg:w-[20%] md:w-[148px] md:h-[148px] h-[100px] w-[100px]
  }
  .ur-card p {
    @apply text-xs sm:text-sm md:text-base text-center
  }
  .mini-head-base {
    @apply text-black text-base font-bold py-2
  }
  .int-card {
    @apply border border-[#000]/15 rounded-xl my-4 py-3 px-5
  }
  .int-card ul {
    @apply list-disc text-start mx-5
  }
  .up-card {
    @apply bg-[#F8F8F8] rounded-xl shadow-lg py-4 px-2 flex flex-col gap-3
  }
  .up-card div {
    @apply flex items-center gap-3
  }
  .up-card div h3 {
    @apply text-black text-base font-bold
  }
  .key-features h3 {
    @apply font-semibold text-[16px]
  }
  .key-features p {
    @apply text-[#505A6F] text-sm
  }
  .update-testing-card {
    @apply flex items-start gap-8
  }
  .update-testing-card .index {
    @apply text-[#1B4BB7] border border-[#000]/10 rounded-2xl bg-[#ffffff] p-2 flex items-center text-[11px]
  }
  .update-testing-card .index span {
    @apply text-white text-stroke text-[50px] font-bold mr-2
  }
  .update-testing-card p {
    @apply text-[13px] lg:text-[16px]
  }
  .gamification-card {
    @apply bg-[#F4F4F6] flex flex-col items-center my-6 p-8
  }
  .view-proto-btn {
    @apply bg-[#D9D9D9] rounded-[2rem] font-bold text-2xl p-3
  }
  .mobilenav {
    @apply ease-in duration-500 md:hidden min-h-fit w-full my-5 z-[999] fixed top-[4rem] left-0 bg-transparent overflow-y-hidden 
  }
}

.worknav-1 {
  color: #046459 !important;
}
.worknav-2 {
  color: #2E5BBE !important;
}
.worknav-3 {
  color: #6A44C7 !important;
}
.worknav-4 {
  color: #CD471E !important;
}
.worknav-5 {
  color: #389394 !important;
}
.modal-open {
  overflow: hidden;
  height: screen;
  /* backdrop-filter: ; */
}

[data-toggle-nav][data-open-nav="true"] #line1 {
transform: translateY(0.375rem) rotate(40deg);
}
[data-toggle-nav][data-open-nav="true"] #line2 {
transform: scaleX(0);
opacity: 0;
}
[data-toggle-nav][data-open-nav="true"] #line3 {
transform: translateY(-0.375rem) rotate(-40deg);
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .text-stroke {
      -webkit-text-stroke-width: 2px;   
      -webkit-text-stroke-color: black;
    }
  }
}